
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Store
import store from "@/store/index";

// Models
import {AppCommonNotification} from "@/models/app/common/notification";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorDashboardRootNotificationsFormCreateIndexVue from "@/views/app/administrator/dashboard/root/notifications/form/create.vue";
import AppAdministratorDashboardRootNotificationsFormUpdateIndexVue from "@/views/app/administrator/dashboard/root/notifications/form/update.vue";
import AppAdministratorDashboardRootNotificationsFormRemoveIndexVue from "@/views/app/administrator/dashboard/root/notifications/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorDashboardRootNotificationsFormCreateIndexVue,
    AppAdministratorDashboardRootNotificationsFormUpdateIndexVue,
    AppAdministratorDashboardRootNotificationsFormRemoveIndexVue
  },
})
export default class AppAdministratorDashboardRootMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      roles: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  notifications: Array<AppCommonNotification> = [];

  get userId(): string {
    return  store.getters.getAuthentication.id;
  }

  async getNotifications(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/notifications`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.notifications = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getNotifications();
  }
}
