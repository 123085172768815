
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Store
import store from "@/store/index";

// Models
import {AppCommonTraining} from "@/models/app/common/training";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {InfoFilled} from "@element-plus/icons-vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    InfoFilled,
  },
})
export default class AppAdministratorDashboardRootTrainingsIndexVue extends Vue {
  isLoading: boolean | null = false;
  formData: any = {
    filter: {
      user_id: "*",
      training_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };
  moment: any = null;

  trainings: Array<AppCommonTraining> = [];

  async getTrainings(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/trainings`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainings = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getTrainings();
  }
}
