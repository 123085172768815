
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Store
import store from "@/store/index";

// Models
import {AppCommonPost} from "@/models/app/common/post";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorDashboardRootPostsFormCreateIndexVue from "@/views/app/administrator/dashboard/root/posts/form/create.vue";
import AppAdministratorDashboardRootPostsFormUpdateIndexVue from "@/views/app/administrator/dashboard/root/posts/form/update.vue";
import AppAdministratorDashboardRootPostsFormRemoveIndexVue from "@/views/app/administrator/dashboard/root/posts/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppAdministratorDashboardRootPostsFormCreateIndexVue,
    AppAdministratorDashboardRootPostsFormUpdateIndexVue,
    AppAdministratorDashboardRootPostsFormRemoveIndexVue
  },
})
export default class AppAdministratorDashboardRootMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      roles: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  posts: Array<AppCommonPost> = [];

  getVideoURL(url): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = (match&&match[7].length==11) ? match[7] : false;

    return `https://www.youtube.com/embed/${id}`;
  }

  async getPosts(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/posts`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.posts = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getPosts();
  }
}
