
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorDashboardNavigationVue from "@/components/administrator/dashboard/navigation.vue";
import AppAdministratorDashboardRootMatchesIndexVue from "@/views/app/administrator/dashboard/root/matches/index.vue";
import AppAdministratorDashboardRootTrainingsIndexVue from "@/views/app/administrator/dashboard/root/trainings/index.vue";
import AppAdministratorDashboardRootNotificationsIndexVue from "@/views/app/administrator/dashboard/root/notifications/index.vue";
import AppAdministratorDashboardRootPostsIndexVue from "@/views/app/administrator/dashboard/root/posts/index.vue";
import AppAdministratorDashboardRootUserInformationIndexVue from "@/views/app/administrator/dashboard/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorDashboardNavigationVue,
    AppAdministratorDashboardRootMatchesIndexVue,
    AppAdministratorDashboardRootTrainingsIndexVue,
    AppAdministratorDashboardRootNotificationsIndexVue,
    AppAdministratorDashboardRootPostsIndexVue,
    AppAdministratorDashboardRootUserInformationIndexVue,
  },
})
export default class AppAdministratorDashboardRootIndexVue extends Vue {}
